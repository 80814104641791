// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-cli-tsx": () => import("./../../../src/pages/cli.tsx" /* webpackChunkName: "component---src-pages-cli-tsx" */),
  "component---src-pages-config-tsx": () => import("./../../../src/pages/config.tsx" /* webpackChunkName: "component---src-pages-config-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */)
}

